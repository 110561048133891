@charset "UTF-8";
/* generated with grunt-sass-globbing */
/*

--- USAGE ---

	@include space(margin, all, $spacing-medium);

*/
/* generated with grunt-sass-globbing */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
  display: block; }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
pre,
samp {
  font-size: 1em; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

audio,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button::-moz-focusring,
[type='button']::-moz-focusring,
[type='reset']::-moz-focusring,
[type='submit']::-moz-focusring {
  outline: 1px dotted ButtonText; }

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

template,
[hidden] {
  display: none; }

abbr,
blockquote,
code,
kbd,
q,
samp,
tt,
var {
  hyphens: none; }

[tabindex='-1']:focus {
  outline: none !important; }

::-moz-selection {
  background: #E2702D;
  color: #212121;
  text-shadow: none; }

::selection {
  background: #E2702D;
  color: #212121;
  text-shadow: none; }

* {
  transition-duration: 0.2s;
  transition-property: background-color, border-color, box-shadow, color, opacity, text-shadow, transform;
  transition-timing-function: linear; }
  *:not(.slides) {
    transition-duration: 0;
    transition-property: none; }
    *:not(.slides) * > * {
      transition-duration: 0;
      transition-property: none; }

*,
*::before,
*::after {
  box-sizing: inherit; }

@media print {
  * {
    background-color: transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important; }
  @page {
    margin: 2cm; } }

html {
  box-sizing: border-box;
  font-size: 100%;
  line-height: 1.3;
  min-height: 100%;
  text-size-adjust: 100%; }

body {
  background-color: #fff;
  color: #5D3724;
  margin: 0;
  padding: 0;
  word-wrap: break-word; }

@media print {
  nav {
    display: none; } }

summary {
  display: list-item; }

iframe {
  border: 0; }
  @media print {
    iframe {
      display: none; } }

h1, .program-slide__list h3, .related h3, h2, h3, h4, .menu--footer .menu__heading > .menu__link, h5, h6, .menu__children .header__social h4, .menu__children .header__languages h4, .display-heading {
  font-family: "Noto Serif", sans-serif;
  color: inherit;
  font-weight: 400;
  hyphens: none;
  line-height: 1.16;
  margin: 0 0 2.5rem;
  text-rendering: optimizeLegibility; }
  @media print {
    h1, .program-slide__list h3, .related h3, h2, h3, h4, .menu--footer .menu__heading > .menu__link, h5, h6, .menu__children .header__social h4, .menu__children .header__languages h4, .display-heading {
      orphans: 3;
      page-break-after: avoid;
      widows: 3; }
      h1::after, .program-slide__list h3::after, .related h3::after, h2::after, h3::after, h4::after, .menu--footer .menu__heading > .menu__link::after, h5::after, h6::after, .menu__children .header__social h4::after, .menu__children .header__languages h4::after, .display-heading::after {
        display: inline-block; } }
  * + h1, .program-slide__list * + h3, .related * + h3, * + h2, * + h3, * + h4, .menu--footer .menu__heading > * + .menu__link, * + h5, * + h6, .menu__children .header__social * + h4, .menu__children .header__languages * + h4, * + .display-heading {
    margin-top: 1em; }
  h1 a, .program-slide__list h3 a, .related h3 a, h2 a, h3 a, h4 a, .menu--footer .menu__heading > .menu__link a, h5 a, h6 a, .menu__children .header__social h4 a, .menu__children .header__languages h4 a, .display-heading a {
    color: #5D3724;
    text-decoration: none; }

h1,
.program-slide__list h3,
.related h3 {
  font-size: 2.5em;
  margin-bottom: 0.5em; }
  @media (min-width: 57.8125em) {
    h1,
    .program-slide__list h3,
    .related h3 {
      font-size: 3em; } }

h2 {
  font-size: 2em; }
  @media (min-width: 57.8125em) {
    h2 {
      font-size: 2.5em; } }

h3 {
  font-size: 1.5em; }
  @media (min-width: 57.8125em) {
    h3 {
      font-size: 2em; } }

h4,
.menu--footer .menu__heading > .menu__link {
  font-size: 1em;
  font-weight: 700; }
  @media (min-width: 57.8125em) {
    h4,
    .menu--footer .menu__heading > .menu__link {
      font-size: 1.5em; } }

h5 {
  font-size: 1em;
  font-weight: 700; }

h6,
.menu__children .header__social h4,
.menu__children .header__languages h4 {
  font-family: "Noto Sans", serif;
  color: #AF4820;
  font-size: 1.25em;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0.5rem; }
  h6 a,
  .menu__children .header__social h4 a,
  .menu__children .header__languages h4 a {
    color: #205493; }

h1 + h1, h2 + h1, h3 + h1, h4 + h1, h5 + h1, h6 + h1, h1 + h2, h2 + h2, h3 + h2, h4 + h2, h5 + h2, h6 + h2, h1 + h3, h2 + h3, h3 + h3, h4 + h3, h5 + h3, h6 + h3, h1 + h4, h2 + h4, h3 + h4, h4 + h4, h5 + h4, h6 + h4, h1 + h5, h2 + h5, h3 + h5, h4 + h5, h5 + h5, h6 + h5, h1 + h6, h2 + h6, h3 + h6, h4 + h6, h5 + h6, h6 + h6 {
  margin-top: 0; }

p {
  margin: 0 0 1.25rem;
  line-height: 1.8; }
  @media print {
    p {
      orphans: 3;
      widows: 3; } }

.p--large {
  font-size: 24px; }

.p--small {
  font-size: 12px; }

.p--centered {
  text-align: center; }

a {
  background-color: transparent;
  color: #AF4820;
  outline-offset: 0.125em;
  -webkit-text-decoration-skip: objects; }
  a:active, a:hover {
    outline: 0; }
  a:active {
    color: #F37029; }
  a:hover, a:focus {
    color: #DE6335; }
  a[href$=".pdf"], a[href$=".doc"], a[href$=".docx"], a[href$=".mp4"], a[href$=".mp3"], a[href$=".gif"], a[href$=".jpg"], a[href$=".png"], a[href$=".jpeg"], a[href$=".webm"], a[href$=".mov"], a[href$=".xls"], a[href$=".xlw"], a[href$=".xlt"], a[href$=".xlsx"], a[href$=".ppt"], a[href$=".pptx"], a[href$=".zip"], a[href$=".rar"], a[href$=".gzip"], a[href$=".tar"] {
    display: inline-block;
    color: #5D3724;
    font-family: "Noto Serif", sans-serif;
    font-weight: 700;
    border-bottom: 4px solid #FBB731;
    padding-bottom: 2px;
    text-decoration: none;
    margin-bottom: 5px; }
    a[href$=".pdf"]:after, a[href$=".doc"]:after, a[href$=".docx"]:after, a[href$=".mp4"]:after, a[href$=".mp3"]:after, a[href$=".gif"]:after, a[href$=".jpg"]:after, a[href$=".png"]:after, a[href$=".jpeg"]:after, a[href$=".webm"]:after, a[href$=".mov"]:after, a[href$=".xls"]:after, a[href$=".xlw"]:after, a[href$=".xlt"]:after, a[href$=".xlsx"]:after, a[href$=".ppt"]:after, a[href$=".pptx"]:after, a[href$=".zip"]:after, a[href$=".rar"]:after, a[href$=".gzip"]:after, a[href$=".tar"]:after {
      content: '';
      display: inline-block;
      margin-left: 4px;
      background-image: linear-gradient(transparent, transparent), url("../images/file.svg");
      background-repeat: no-repeat;
      background-position: center right;
      padding-left: 11px;
      fill: #5D3724;
      height: 15px;
      overflow: hidden;
      width: 15px;
      background-size: contain; }
      a[href$=".pdf"]:after::before, a[href$=".doc"]:after::before, a[href$=".docx"]:after::before, a[href$=".mp4"]:after::before, a[href$=".mp3"]:after::before, a[href$=".gif"]:after::before, a[href$=".jpg"]:after::before, a[href$=".png"]:after::before, a[href$=".jpeg"]:after::before, a[href$=".webm"]:after::before, a[href$=".mov"]:after::before, a[href$=".xls"]:after::before, a[href$=".xlw"]:after::before, a[href$=".xlt"]:after::before, a[href$=".xlsx"]:after::before, a[href$=".ppt"]:after::before, a[href$=".pptx"]:after::before, a[href$=".zip"]:after::before, a[href$=".rar"]:after::before, a[href$=".gzip"]:after::before, a[href$=".tar"]:after::before {
        content: '';
        display: block;
        height: 150%;
        width: 0; }
    a[href$=".pdf"]:hover, a[href$=".pdf"]:focus, a[href$=".doc"]:hover, a[href$=".doc"]:focus, a[href$=".docx"]:hover, a[href$=".docx"]:focus, a[href$=".mp4"]:hover, a[href$=".mp4"]:focus, a[href$=".mp3"]:hover, a[href$=".mp3"]:focus, a[href$=".gif"]:hover, a[href$=".gif"]:focus, a[href$=".jpg"]:hover, a[href$=".jpg"]:focus, a[href$=".png"]:hover, a[href$=".png"]:focus, a[href$=".jpeg"]:hover, a[href$=".jpeg"]:focus, a[href$=".webm"]:hover, a[href$=".webm"]:focus, a[href$=".mov"]:hover, a[href$=".mov"]:focus, a[href$=".xls"]:hover, a[href$=".xls"]:focus, a[href$=".xlw"]:hover, a[href$=".xlw"]:focus, a[href$=".xlt"]:hover, a[href$=".xlt"]:focus, a[href$=".xlsx"]:hover, a[href$=".xlsx"]:focus, a[href$=".ppt"]:hover, a[href$=".ppt"]:focus, a[href$=".pptx"]:hover, a[href$=".pptx"]:focus, a[href$=".zip"]:hover, a[href$=".zip"]:focus, a[href$=".rar"]:hover, a[href$=".rar"]:focus, a[href$=".gzip"]:hover, a[href$=".gzip"]:focus, a[href$=".tar"]:hover, a[href$=".tar"]:focus {
      color: #AF4820; }
      a[href$=".pdf"]:hover:after, a[href$=".pdf"]:focus:after, a[href$=".doc"]:hover:after, a[href$=".doc"]:focus:after, a[href$=".docx"]:hover:after, a[href$=".docx"]:focus:after, a[href$=".mp4"]:hover:after, a[href$=".mp4"]:focus:after, a[href$=".mp3"]:hover:after, a[href$=".mp3"]:focus:after, a[href$=".gif"]:hover:after, a[href$=".gif"]:focus:after, a[href$=".jpg"]:hover:after, a[href$=".jpg"]:focus:after, a[href$=".png"]:hover:after, a[href$=".png"]:focus:after, a[href$=".jpeg"]:hover:after, a[href$=".jpeg"]:focus:after, a[href$=".webm"]:hover:after, a[href$=".webm"]:focus:after, a[href$=".mov"]:hover:after, a[href$=".mov"]:focus:after, a[href$=".xls"]:hover:after, a[href$=".xls"]:focus:after, a[href$=".xlw"]:hover:after, a[href$=".xlw"]:focus:after, a[href$=".xlt"]:hover:after, a[href$=".xlt"]:focus:after, a[href$=".xlsx"]:hover:after, a[href$=".xlsx"]:focus:after, a[href$=".ppt"]:hover:after, a[href$=".ppt"]:focus:after, a[href$=".pptx"]:hover:after, a[href$=".pptx"]:focus:after, a[href$=".zip"]:hover:after, a[href$=".zip"]:focus:after, a[href$=".rar"]:hover:after, a[href$=".rar"]:focus:after, a[href$=".gzip"]:hover:after, a[href$=".gzip"]:focus:after, a[href$=".tar"]:hover:after, a[href$=".tar"]:focus:after {
        fill: #AF4820; }
  a[href$=".pdf"]:after {
    background-image: linear-gradient(transparent, transparent), url("../images/file-pdf.svg"); }
  a[href$=".zip"]:after, a[href$=".rar"]:after, a[href$=".gzip"]:after, a[href$=".tar"]:after {
    background-image: linear-gradient(transparent, transparent), url("../images/file-pdf.svg"); }
  a[href$=".xls"]:after, a[href$=".xlw"]:after, a[href$=".xlt"]:after, a[href$=".xlsx"]:after {
    background-image: linear-gradient(transparent, transparent), url("../images/file-excel.svg"); }
  a[href$=".ppt"]:after, a[href$=".pptx"]:after {
    background-image: linear-gradient(transparent, transparent), url("../images/file-powerpoint.svg"); }
  a[href$=".doc"]:after, a[href$=".docx"]:after {
    background-image: linear-gradient(transparent, transparent), url("../images/file-word.svg"); }
  a[href$=".mp4"]:after, a[href$=".mp3"]:after {
    background-image: linear-gradient(transparent, transparent), url("../images/file-audio.svg"); }
  a[href$=".webm"]:after, a[href$=".mov"]:after {
    background-image: linear-gradient(transparent, transparent), url("../images/file-video.svg"); }
  a[href$=".gif"]:after, a[href$=".jpg"]:after, a[href$=".png"]:after, a[href$=".jpeg"]:after {
    background-image: linear-gradient(transparent, transparent), url("../images/file-image.svg"); }
  @media print {
    a, a:visited {
      text-decoration: none; }
    a[href]::after {
      font-family: "Noto Sans", serif;
      content: " <" attr(href) ">";
      font-size: 10pt;
      font-weight: normal;
      text-transform: lowercase; }
    a[href^='javascript:']::after, a[href^='mailto:']::after, a[href^='tel:']::after, a[href^='#']::after, a[href*='?']::after {
      content: ''; } }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

abbr:hover {
  cursor: help; }

@media print {
  abbr[title]::after {
    content: " (" attr(title) ")"; } }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 1em; }

strong {
  font-weight: 600; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

blockquote {
  margin: 0 1.5em 2.5rem;
  font-family: "Noto Serif", sans-serif;
  color: #AF4820;
  font-size: 24px;
  font-style: italic;
  background-image: url(../images/blockquote.png);
  background-size: 45px 45px;
  background-repeat: no-repeat;
  background-position: left 5px;
  padding-left: 55px;
  padding-top: 15px;
  padding-bottom: 5px; }
  @media (min-width: 57.8125em) {
    blockquote {
      margin-left: 7.5rem; } }
  blockquote cite {
    display: block;
    margin-top: 0.5em; }
    blockquote cite:before {
      content: "\2013";
      margin-right: 0.5em; }
  @media print {
    blockquote {
      page-break-inside: avoid; } }

pre {
  margin: 0 0 2.5rem;
  overflow: auto; }
  @media print {
    pre {
      page-break-inside: avoid; } }

hr {
  border: 1px solid #F37029;
  box-sizing: content-box;
  height: 0;
  margin: 2.5rem 0;
  padding-bottom: -1px;
  border-radius: 4px; }

ul {
  list-style-type: disc;
  margin: 0 0 2.5rem;
  padding: 0 0 0 1.2em; }
  [dir='rtl'] ul {
    padding: 0 1.2em 0 0; }
  ul ol,
  ul ul {
    margin: 1.25rem 0 0; }
  ul ul {
    list-style-type: circle; }
  ul ul ul {
    list-style-type: square; }

ol {
  margin: 0 0 2.5rem;
  padding: 0 0 0 1.5em; }
  [dir='rtl'] ol {
    padding: 0 1.5em 0 0; }
  ol ol,
  ol ul {
    margin: 1.25rem 0 0; }
  ol ol {
    list-style-type: lower-alpha; }
  ol ol ol {
    list-style-type: lower-roman; }

li {
  margin-bottom: 1.25rem; }
  li:last-child {
    margin-bottom: 0; }
  @media print {
    li {
      page-break-inside: avoid; } }

dd {
  margin-bottom: 2.5rem; }

dl {
  margin: 0 0 2.5rem; }

caption {
  margin-bottom: 1.25rem; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 2.5rem;
  min-width: 100%; }

td {
  background-color: #ffffff;
  border: 1px solid #5b616b;
  padding: 0.9375rem; }

tfoot td,
tfoot th {
  background-color: #f1f1f1; }

th {
  background-color: #ffffff;
  border: 1px solid #5b616b;
  padding: 0.9375rem;
  text-align: left; }

@media print {
  thead {
    display: table-header-group; } }

thead td,
thead th {
  background-color: #f1f1f1; }

@media print {
  tr {
    page-break-inside: avoid; } }

button {
  overflow: visible; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

input {
  line-height: normal; }

legend {
  border: 0;
  box-sizing: border-box;
  color: inherit;
  display: table;
  margin: 0;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

optgroup {
  font-weight: bold; }

textarea {
  overflow: auto; }

audio:not([controls]) {
  display: none;
  height: 0; }

canvas {
  display: inline-block; }

figure {
  margin: 0 0 2.5rem; }

img {
  border: 0;
  font-style: italic;
  height: auto;
  max-width: 100%;
  vertical-align: middle; }
  main img {
    border-radius: 4px; }
  @media print {
    img {
      max-width: 100% !important;
      page-break-inside: avoid; } }

svg:not(:root) {
  overflow: hidden; }

.breadcrumb__title, .form-item__checkbox {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
  word-wrap: normal; }

.menu--mobile .menu__link, .mobile-menu__button, .tabs__link, .skiplinks {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Open Sans", "Helvetica Neue", sans-serif; }

html, .button, .menu--footer .menu__donate .menu__link, .menu--main .menu__item.menu__donate .menu__link, .mobile-menu__donate, .tabs__link {
  font-family: "Noto Sans", serif; }

/* generated with grunt-sass-globbing */
.l-constrain {
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
  padding-left: 1em;
  padding-right: 1em; }
  .l-constrain .l-constrain {
    padding-left: 0;
    padding-right: 0; }

.l-constrain--narrow {
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 865px; }
  .l-constrain--narrow .l-constrain--narrow {
    padding-left: 0;
    padding-right: 0; }

.l-constrain--wide {
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 125rem; }
  .l-constrain--wide .l-constrain--wide {
    padding-left: 0;
    padding-right: 0; }

.l-constrain--no-padding {
  padding-left: 0;
  padding-right: 0; }

.l-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1em;
  margin-right: -1em; }
  .l-grid > * {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    width: calc((100%) - 2em);
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 2em;
    min-width: 0; }
  @supports (display: grid) {
    .l-grid {
      margin-left: 0;
      margin-right: 0;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2em;
      display: grid; }
      .l-grid > * {
        margin: 0;
        min-width: 0;
        width: 100%; } }

.l-grid--2col {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1em;
  margin-right: -1em; }
  .l-grid--2col > * {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    width: calc((100%) - 2em);
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 2em;
    min-width: 0; }
  @supports (display: grid) {
    .l-grid--2col {
      margin-left: 0;
      margin-right: 0;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2em;
      display: grid; }
      .l-grid--2col > * {
        margin: 0;
        min-width: 0;
        width: 100%; } }
  @media (min-width: 37.5em) {
    .l-grid--2col > * {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: calc((50%) - 2em);
      margin-left: 1em;
      margin-right: 1em;
      margin-bottom: 2em;
      min-width: 275px; }
    @supports (display: grid) {
      .l-grid--2col {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2em; }
        .l-grid--2col > * {
          margin: 0;
          min-width: 0;
          width: 100%; } } }

.l-grid--3col {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1em;
  margin-right: -1em; }
  .l-grid--3col > * {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    width: calc((100%) - 2em);
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 2em;
    min-width: 0; }
  @supports (display: grid) {
    .l-grid--3col {
      margin-left: 0;
      margin-right: 0;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2em;
      display: grid; }
      .l-grid--3col > * {
        margin: 0;
        min-width: 0;
        width: 100%; } }
  @media (min-width: 47.5em) {
    .l-grid--3col > * {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: calc((33.3333333333%) - 2em);
      margin-left: 1em;
      margin-right: 1em;
      margin-bottom: 2em;
      min-width: 275px; }
    @supports (display: grid) {
      .l-grid--3col {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2em; }
        .l-grid--3col > * {
          margin: 0;
          min-width: 0;
          width: 100%; } } }

.l-grid--4col {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1em;
  margin-right: -1em; }
  .l-grid--4col > * {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    width: calc((100%) - 2em);
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 2em;
    min-width: 0; }
  @supports (display: grid) {
    .l-grid--4col {
      margin-left: 0;
      margin-right: 0;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2em;
      display: grid; }
      .l-grid--4col > * {
        margin: 0;
        min-width: 0;
        width: 100%; } }
  @media (min-width: 37.5em) and (max-width: 50em) {
    .l-grid--4col > * {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: calc((50%) - 2em);
      margin-left: 1em;
      margin-right: 1em;
      margin-bottom: 2em;
      min-width: 275px; }
    @supports (display: grid) {
      .l-grid--4col {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2em; }
        .l-grid--4col > * {
          margin: 0;
          min-width: 0;
          width: 100%; } } }
  @media (min-width: 50.0625em) {
    .l-grid--4col > * {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: calc((25%) - 2em);
      margin-left: 1em;
      margin-right: 1em;
      margin-bottom: 2em;
      min-width: 0; }
    @supports (display: grid) {
      .l-grid--4col {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 2em; }
        .l-grid--4col > * {
          margin: 0;
          min-width: 0;
          width: 100%; } } }

.l-grid--6col {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1em;
  margin-right: -1em; }
  .l-grid--6col > * {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    width: calc((100%) - 2em);
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 2em;
    min-width: 0; }
  @supports (display: grid) {
    .l-grid--6col {
      margin-left: 0;
      margin-right: 0;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 2em;
      display: grid; }
      .l-grid--6col > * {
        margin: 0;
        min-width: 0;
        width: 100%; } }
  @media (min-width: 37.5em) and (max-width: 62.4375em) {
    .l-grid--6col > * {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: calc((33.3333333333%) - 2em);
      margin-left: 1em;
      margin-right: 1em;
      margin-bottom: 2em;
      min-width: 0; }
    @supports (display: grid) {
      .l-grid--6col {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2em; }
        .l-grid--6col > * {
          margin: 0;
          min-width: 0;
          width: 100%; } } }
  @media (min-width: 62.5em) {
    .l-grid--6col > * {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: calc((16.6666666667%) - 2em);
      margin-left: 1em;
      margin-right: 1em;
      margin-bottom: 2em;
      min-width: 0; }
    @supports (display: grid) {
      .l-grid--6col {
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 2em; }
        .l-grid--6col > * {
          margin: 0;
          min-width: 0;
          width: 100%; } } }

.l-media {
  display: flex;
  flex-direction: column; }
  @media (min-width: 37.5em) {
    .l-media {
      flex-direction: row; } }

.l-media__object {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  margin: 0 1rem 0 0; }

.l-media__content {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto; }

.l-media--column {
  flex-direction: column; }

.l-media--reversed > .l-media__object {
  margin: 0 0 0 1rem;
  order: 1; }

.footer {
  background-color: #FFF5E8;
  padding: 3rem 0; }
  .footer h4 {
    margin-bottom: 1rem; }

@media (min-width: 0) and (max-width: 37.5em) {
  .footer__grid {
    max-width: 200px;
    margin-left: auto;
    margin-right: auto; } }

@media (min-width: 37.5em) {
  .footer__grid {
    display: grid;
    grid-template-columns: 100px 1fr 250px;
    grid-template-rows: 1fr;
    grid-column-gap: 40px; } }

@media (min-width: 54.375em) {
  .footer__grid {
    grid-template-columns: 150px 1fr 280px; } }

@media (min-width: 64em) {
  .footer__grid {
    grid-template-columns: 180px 1fr 340px; } }

.footer__partners,
.footer__websites {
  margin-top: 2rem;
  margin-bottom: 2rem; }

@media (min-width: 50em) {
  .footer__last {
    border-left: 2px solid #CDBDAE;
    padding-left: 2rem; } }

.footer__partner {
  display: inline-block;
  margin-right: 1rem; }
  .footer__partner:last-child {
    margin-right: 0; }
  .footer__partner::after {
    display: none !important; }
  .footer__partner img {
    max-height: 55px;
    margin-bottom: 1rem; }

.footer__website {
  display: inline-block;
  margin-right: 1rem; }
  .footer__website:last-child {
    margin-right: 0; }
  .footer__website::after {
    display: none !important; }
  .footer__website img {
    max-height: 100px;
    margin-bottom: 1rem; }

.header {
  background-color: #FFF5E8;
  position: relative;
  z-index: 50; }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .header {
      display: none; } }

.header__grid {
  align-items: flex-end;
  display: none;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  padding-top: 1rem;
  padding-bottom: 1rem; }
  @media (min-width: 57.8125em) {
    .header__grid {
      display: grid; } }
  @media (min-width: 75em) {
    .header__grid {
      grid-template-columns: 250px 1fr; } }

.header__second {
  text-align: right;
  overflow: hidden; }
  .header__second.header-search-open .menu__children,
  .header__second.header-search-open .menu__subnav {
    display: none; }
  .header__second.header-search-open .menu--main .menu__item.menu__search .menu__link {
    background-image: linear-gradient(transparent, transparent), url("../images/mobile-close.svg"); }

.header__search {
  background-color: #FFFCF7;
  text-align: right;
  z-index: 57;
  position: relative; }

.header__first {
  position: relative;
  z-index: 56; }

.l-navigation {
  display: none; }
  @media (min-width: 57.8125em) {
    .l-navigation {
      display: block; } }

.l-section {
  margin-bottom: 2em; }

.l-section--no-bottom-space {
  margin-bottom: 0; }

@media (min-width: 56.25em) {
  .l-sidebar {
    display: flex;
    flex-wrap: wrap; }
    .l-sidebar > * {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: calc(100% - 300px - 2rem);
      margin-right: 1rem; }
      .l-sidebar > *:last-child {
        margin-right: 0; }
    .l-sidebar .l-sidebar__full {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100%;
      margin-right: 0; }
    .l-sidebar .l-sidebar__sidebar {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 300px; }
    @supports (display: grid) {
      .l-sidebar {
        display: grid;
        grid-template-columns: 1fr 300px;
        grid-gap: 1rem; }
        .l-sidebar .l-sidebar__full {
          grid-column: span 2; } } }

@media (min-width: 56.25em) {
  .l-sidebar--multi-2 > * {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(100% - 600px - 3rem); }
  @supports (display: grid) {
    .l-sidebar--multi-2 {
      grid-template-columns: 300px 1fr 300px; }
      .l-sidebar--multi-2 .l-sidebar__full {
        grid-column: span 3; } } }

/* generated with grunt-sass-globbing */
.a2a_kit a::after {
  background-image: none !important; }

.aligncenter {
  display: block;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }

.alignleft {
  max-width: 100%; }

.alignleft {
  float: none;
  margin: 0; }

@media (min-width: 43.75em) {
  .alignleft {
    float: left;
    margin: 0 1em 1em 0; } }

.alignright {
  max-width: 100%; }

.alignright {
  float: none;
  margin: 0; }

@media (min-width: 43.75em) {
  .alignright {
    float: right;
    margin: 0 0 1em 1em; } }

span.ctct-label-top,
.ctct-form-description,
.ctct-form-field-hidden,
.ctct-disclosure,
.button.ctct-button {
  display: none !important; }

.ctct-message {
  width: 25rem;
  position: absolute;
  left: -100px;
  right: -100px; }

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px; }

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; }

.sticky {
  display: block; }

.wp-caption {
  max-width: 100%; }
  .wp-caption .wp-caption-text {
    max-width: 100%;
    font-style: italic;
    font-size: 14px;
    color: #372717; }

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #FBB731;
  border-radius: 3px;
  color: #5D3724;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  margin-bottom: 10px;
  border: none;
  text-transform: uppercase; }

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .accordion:hover,
button:focus {
  background-color: #E2702D;
  background: #E2702D;
  border: none; }

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden; }

.accordion:after {
  content: '\02795';
  /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: #777;
  float: right;
  margin-left: 5px; }

.active:after {
  content: "\2796";
  /* Unicode character for "minus" sign (-) */ }

.archives-listing {
  background-color: #94a533;
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-bottom: 0; }

.archive .hero--large,
.search-results .hero--large {
  margin-bottom: 0; }

.article footer {
  padding-bottom: 2.5rem; }

.blog-meta > * {
  display: inline-block; }

.blog-meta .blog-meta__author:after {
  content: '|';
  color: #F37029;
  display: inline-block;
  padding-left: 0.5rem;
  margin-right: 0.5rem; }

.blog-meta .blog-meta__author img {
  max-width: 50px;
  border-radius: 50%;
  margin-right: 0.5rem; }

.breadcrumb {
  margin: 2.5rem 0; }
  .breadcrumb::after {
    clear: both;
    content: '';
    display: table; }

.breadcrumb__list {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0; }
  .breadcrumb__list > li {
    display: inline-block; }

.breadcrumb__item {
  font-size: 16px;
  margin-right: 0.25em; }
  .breadcrumb__item::after {
    content: '/';
    margin-left: 0.25em; }
  .breadcrumb__item:last-child::after {
    content: '';
    margin-left: 0; }

.breadcrumb__link {
  text-decoration: none; }

.button {
  background-color: #FBB731;
  border: 0;
  border-radius: 3px;
  color: #5D3724;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75rem 1.25rem;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: normal;
  margin: 0 0 0.5em;
  text-transform: uppercase; }
  .button:focus, .button:hover {
    background-color: #F79433;
    color: #5D3724;
    outline: 0; }
  .button:active {
    background-color: #F79433;
    color: #5D3724; }
  .button[disabled] {
    background-color: rgba(253, 185, 51, 0.5);
    color: #5D3724;
    cursor: default;
    pointer-events: none; }
  .button + .button {
    margin-left: 0.5em; }
    [dir='rtl'] .button + .button {
      margin-left: 0;
      margin-right: 0.5em; }

.button--danger {
  background-color: #e31c3d;
  color: #fff; }
  .button--danger:focus, .button--danger:hover {
    background-color: #cd2026;
    color: #fff; }
  .button--danger:active {
    background-color: #981b1e;
    color: #fff; }

.button--large {
  font-size: 2rem;
  line-height: 0.78125;
  padding: 0.9375rem 1.875rem; }

.button--secondary {
  background-color: #fff;
  border: 2px solid #F37029;
  color: #AF4820; }
  .button--secondary:focus, .button--secondary:hover {
    background-color: #fff;
    border-color: #DE6335;
    color: #AF4820; }
  .button--secondary:active {
    background-color: #fff;
    border-color: #AF4820;
    color: #AF4820; }

.button--small,
.searchform--header .button {
  font-size: 1rem;
  line-height: 1.0625;
  padding: 0.5rem 1rem; }

.callout-link {
  color: #5D3724;
  font-family: "Noto Serif", sans-serif;
  font-weight: 700;
  border-bottom: 4px solid #FBB731;
  padding-bottom: 2px;
  text-decoration: none; }

.card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.24);
  transition: all linear 0.1s; }
  .card:hover {
    box-shadow: 0 0 34px 5px rgba(0, 0, 0, 0.24); }
    .card:hover .card__title a::after {
      width: 24px; }

.card__body {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem; }

.card__title a {
  display: block; }
  .card__title a:visited {
    color: #5D3724; }
  .card__title a::after {
    content: '';
    background-color: #F37029;
    height: 5px;
    width: 80px;
    display: block;
    border-radius: 4px;
    margin-top: 2rem;
    transition: all linear 0.1s; }
  .card__title a:hover::after {
    width: 24px; }

.card__content {
  margin-bottom: 0.5rem; }

.card__media {
  order: -1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }
  .card__media img {
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }

.card__footer {
  margin-top: auto;
  color: #AF4820;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  padding-top: 2rem; }

.card__tags {
  margin-top: 0.25rem; }

.card__date {
  text-align: right;
  font-size: 12px; }

.card__type {
  font-size: 12px;
  font-weight: 700; }

.copyright {
  font-size: 0.875rem;
  margin-top: 2rem;
  text-align: center; }

.cutout {
  margin-bottom: 4rem;
  margin-top: 4rem; }
  @media (min-width: 57.8125em) {
    .cutout:nth-child(even) .cutout__image {
      order: 2; } }
  .cutout .moons {
    margin-top: 4rem; }

.cutout__link {
  color: #5D3724;
  font-family: "Noto Serif", sans-serif;
  font-weight: 700;
  border-bottom: 4px solid #FBB731;
  padding-bottom: 2px;
  text-decoration: none; }

.cutout__image {
  position: relative; }
  .cutout__image:hover .cutout__caption {
    opacity: 1; }

.cutout__caption {
  opacity: 0;
  margin-top: 2.5rem;
  font-style: italic;
  font-size: 14px;
  color: #372717; }

.date {
  font-weight: 700; }

details.details {
  margin: 0 0 1.875rem; }
  .js details.details:not([open]) > .details__content {
    display: none; }

.details__summary {
  background-image: linear-gradient(transparent, transparent), url("../images/plus.svg");
  background-color: #f1f1f1;
  background-position: right 1.875rem center;
  background-repeat: no-repeat;
  background-size: 0.8125rem;
  cursor: pointer;
  display: block;
  font-weight: bold;
  line-height: 1;
  outline: 0;
  padding: 0.9375rem 3.4375rem 0.9375rem 1.875rem; }
  [dir='rtl'] .details__summary {
    background-position: left 1.875rem center;
    padding-left: 3.4375rem;
    padding-right: 1.875rem; }
  [open] > .details__summary {
    background-image: linear-gradient(transparent, transparent), url("../images/minus.svg"); }
  .details__summary:hover {
    background-color: #d6d7d9; }
  .details__summary:focus {
    box-shadow: 0 0 3px #3e94cf, 0 0 7px #3e94cf; }
  .details__summary::-webkit-details-marker {
    display: none; }

.details__fallback-link {
  color: inherit !important;
  display: block;
  outline: 0;
  text-decoration: none; }

.details__content {
  background: #fff;
  border: 3px solid #f1f1f1;
  border-top: 0;
  padding: 1.875rem; }
  .details__content > :last-child {
    margin-bottom: 0; }

.details__description {
  color: #AF4820;
  font-size: 1rem;
  margin-bottom: 2.5rem; }
  .details__description > :last-child {
    margin-bottom: 0; }

.display-heading {
  color: #212121;
  font-size: 2.5em;
  margin-bottom: 0.5em; }
  @media (min-width: 57.8125em) {
    .display-heading {
      font-size: 4em; } }

.event-meta {
  background-repeat: no-repeat;
  background-position: left 3px;
  padding-left: 25px;
  margin-bottom: 1rem; }
  .event-meta p {
    margin-bottom: 0; }

.event-meta__time {
  background-image: url(../images/icon--time.png);
  background-size: 14px 14px; }

.event-meta__location {
  background-image: url(../images/icon--location.png);
  background-size: 19px 19px; }

.event-meta__host {
  background-image: linear-gradient(transparent, transparent), url("../images/icon--host.svg");
  background-size: 20px 15px; }

.event-meta__price {
  background-image: linear-gradient(transparent, transparent), url("../images/icon--price.svg");
  background-size: 16px 11px; }

@media (min-width: 57.8125em) {
  .facets .l-grid--2col {
    grid-gap: 0;
    grid-template-columns: 1fr 250px; } }

.facet {
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 1rem;
  position: relative; }

.facet__title {
  background-image: linear-gradient(transparent, transparent), url("../images/plus-orange.svg");
  background-repeat: no-repeat;
  background-position: 95% center;
  padding: 0.5rem;
  padding-right: 40px;
  padding-left: 1.5rem;
  margin-bottom: 0;
  display: block;
  border: 1px solid #CDBDAE;
  border-radius: 4px;
  position: relative;
  z-index: 6; }
  .is-open .facet__title {
    background-image: linear-gradient(transparent, transparent), url("../images/minus-orange.svg"); }
  .facet__title:hover {
    background-color: #FBB731;
    cursor: pointer; }

.facetwp-facet:not(.facetwp-type-search) {
  display: none;
  margin-bottom: 0 !important;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #FFF5E8;
  border: 1px solid #CDBDAE;
  min-width: 14rem;
  z-index: 10; }

.facetwp-counter {
  display: none; }

.facetwp-checkbox {
  background-position: 0.5rem 10px !important;
  border-bottom: 1px solid #CDBDAE;
  padding: 0.5rem 0.5rem 0.5rem 30px !important;
  font-size: 0.875rem;
  margin-bottom: 0; }
  .facetwp-checkbox:last-child {
    border-bottom: 0; }

.facetwp-pager,
.pager__items {
  text-align: center; }

.facetwp-pager {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }
  .facetwp-pager:empty {
    margin: 0; }

.facetwp-page,
.pager__link,
.pager__item {
  font-family: "Noto Serif", sans-serif;
  margin: 0 0.25rem !important;
  padding: 0.625rem 0.8125rem !important;
  display: inline-block;
  line-height: 1;
  text-decoration: none;
  border-radius: 50%; }
  .facetwp-page.active, .facetwp-page.pager__item--current,
  .pager__link.active,
  .pager__link.pager__item--current,
  .pager__item.active,
  .pager__item.pager__item--current {
    background-color: #AF4820;
    color: #fff;
    font-weight: 400; }

.facetwp-page-next,
.facetwp-page-prev {
  font-family: "Noto Sans", serif; }
  .facetwp-page-next.active,
  .facetwp-page-prev.active {
    background-color: transparent; }

.facets__selected {
  margin-top: 1rem; }
  .facets__selected span {
    display: inline-block; }
  .facets__selected ul {
    margin: 0 !important;
    padding: 0 !important; }
  .facets__selected .facetwp-selection-label {
    display: none; }
  .facets__selected .facetwp-selection-value {
    background-position: left center !important;
    padding-right: 0 !important;
    padding-left: 20px;
    background-image: url(../images/filter-remove.svg) !important;
    font-size: 0.875rem;
    color: #7D4A30; }

.facetwp-selections {
  display: inline-block; }

.facetwp-template {
  background-color: #94a533;
  padding-top: 4rem;
  padding-bottom: 4rem; }

.facetwp-type-search {
  margin: 0; }

.facetwp-search {
  margin: 0 !important;
  width: 100%; }

.facetwp-btn {
  background-color: #FBB731;
  opacity: 1 !important;
  right: 0 !important;
  bottom: 0;
  padding: 0; }
  .facetwp-btn:before {
    background-position: center !important;
    width: 40px !important;
    height: 45px !important; }

.facetwp-search-wrap {
  height: 100%;
  display: block; }

@media (min-width: 0) and (max-width: 37.5em) {
  .facet__filters {
    max-width: 85%; } }

.facetwp-template.l-section {
  margin-bottom: 0; }

.fieldset {
  margin-bottom: 3.175rem;
  padding: 0;
  position: relative;
  top: 1.3rem; }

.fieldset__legend {
  box-sizing: content-box;
  display: block;
  left: -1px;
  position: absolute;
  text-indent: 0;
  top: -1.3rem;
  width: 100%; }
  [dir='rtl'] .fieldset__legend {
    left: auto;
    right: -1px; }

.fieldset__legend-text {
  display: block; }

.fieldset__legend-link {
  display: block; }

.fieldset__description {
  color: #AF4820;
  font-size: 1rem; }
  .fieldset__description > :last-child {
    margin-bottom: 0; }

.fieldset--default {
  background: #fff;
  border: 1px solid #aeb0b5;
  margin: 2.5rem 0 3.25rem;
  padding: 0 1em; }
  .fieldset--default .fieldset__legend {
    background: #d6d7d9;
    border: 1px solid #aeb0b5;
    border-bottom: none;
    color: #212121;
    height: 2em;
    line-height: 2;
    padding: 0;
    text-shadow: 0 1px 0 #fff; }
  .fieldset--default .fieldset__legend-text {
    padding-left: 1em; }
    [dir='rtl'] .fieldset--default .fieldset__legend-text {
      padding-left: 0;
      padding-right: 1em; }
  .fieldset--default .fieldset__content {
    margin-top: 2.5em; }
  .fieldset--default .fieldset__description {
    font-style: italic;
    margin: 2.5rem 0; }

.form-item {
  margin: 0 0 1.875rem; }

.form-item__label {
  display: block; }
  .is-disabled > .form-item__label {
    color: #AF4820; }

.form-item__fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

.form-item__description {
  color: #AF4820;
  font-size: 0.75rem; }
  .form-item__description > :last-child {
    margin-bottom: 0; }

.form-item__required-marker::before {
  content: '*'; }


.form-item__color,
.form-item__date,
.form-item__email,
.form-item__month,
.form-item__number,
.form-item__password,
.form-item__search,
.form-item__select,
.form-item__tel,
.form-item__text,
.form-item__textarea,
.form-item__time,
.form-item__url,
.form-item__week {
  appearance: none;
  background-color: #FFFCF7;
  border: 1px solid #CDBDAE;
  border-radius: 0;
  display: inline-block;
  font-size: 1rem;
  height: 2.75rem;
  line-height: 1.375;
  padding: 0.625em 0.4375em;
  margin: 0.2em 0;
  max-width: 100%; }
  
  .form-item__color:focus,
  .form-item__date:focus,
  .form-item__email:focus,
  .form-item__month:focus,
  .form-item__number:focus,
  .form-item__password:focus,
  .form-item__search:focus,
  .form-item__select:focus,
  .form-item__tel:focus,
  .form-item__text:focus,
  .form-item__textarea:focus,
  .form-item__time:focus,
  .form-item__url:focus,
  .form-item__week:focus {
    box-shadow: 0 0 3px #AF4820, 0 0 7px #AF4820;
    outline: 0;
    background-color: #fff; }
  
  .form-item__color:hover,
  .form-item__color:active,
  .form-item__date:hover,
  .form-item__date:active,
  .form-item__email:hover,
  .form-item__email:active,
  .form-item__month:hover,
  .form-item__month:active,
  .form-item__number:hover,
  .form-item__number:active,
  .form-item__password:hover,
  .form-item__password:active,
  .form-item__search:hover,
  .form-item__search:active,
  .form-item__select:hover,
  .form-item__select:active,
  .form-item__tel:hover,
  .form-item__tel:active,
  .form-item__text:hover,
  .form-item__text:active,
  .form-item__textarea:hover,
  .form-item__textarea:active,
  .form-item__time:hover,
  .form-item__time:active,
  .form-item__url:hover,
  .form-item__url:active,
  .form-item__week:hover,
  .form-item__week:active {
    background-color: #fff;
    border-color: #212121; }
  
  .form-item__color:disabled,
  .form-item__date:disabled,
  .form-item__email:disabled,
  .form-item__month:disabled,
  .form-item__number:disabled,
  .form-item__password:disabled,
  .form-item__search:disabled,
  .form-item__select:disabled,
  .form-item__tel:disabled,
  .form-item__text:disabled,
  .form-item__textarea:disabled,
  .form-item__time:disabled,
  .form-item__url:disabled,
  .form-item__week:disabled {
    background-color: rgba(255, 252, 247, 0.5);
    border-color: rgba(205, 189, 174, 0.5);
    cursor: default; }
  .is-full-width >
  .form-item__color, .is-full-width >
  .form-item__date, .is-full-width >
  .form-item__email, .is-full-width >
  .form-item__month, .is-full-width >
  .form-item__number, .is-full-width >
  .form-item__password, .is-full-width >
  .form-item__search, .is-full-width >
  .form-item__select, .is-full-width >
  .form-item__tel, .is-full-width >
  .form-item__text, .is-full-width >
  .form-item__textarea, .is-full-width >
  .form-item__time, .is-full-width >
  .form-item__url, .is-full-width >
  .form-item__week {
    width: 100%; }

.form-item__textarea {
  height: auto; }

.form-item--checkbox {
  margin-bottom: 0.5em; }

.form-item__checkbox + .form-item__label {
  cursor: pointer;
  display: block; }
  .form-item__checkbox + .form-item__label::before {
    background: #FFFCF7;
    border-radius: 0.1875rem;
    box-shadow: 0 0 0 1px #CDBDAE;
    content: '\a0';
    display: inline-block;
    height: 1.125rem;
    line-height: 1.125rem;
    margin-right: 0.6em;
    text-indent: 0.15em;
    transition-duration: 0.2s;
    transition-property: background-color, border-color, box-shadow, color, opacity, text-shadow, transform;
    transition-timing-function: ease-in-out;
    vertical-align: middle;
    width: 1.125rem; }

.form-item__checkbox:checked + .form-item__label::before {
  background-image: linear-gradient(transparent, transparent), url("../images/correct.svg");
  background-color: #AF4820;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  box-shadow: 0 0 0 1px #CDBDAE; }

.form-item__checkbox:focus + .form-item__label::before {
  box-shadow: 0 0 0 2px #FFFCF7, 0 0 0 4px #AF4820, 0 0 3px 4px #AF4820, 0 0 7px 4px #AF4820; }

.form-item__checkbox:checked:disabled + .form-item__label::before {
  background-color: rgba(93, 55, 36, 0.5); }

.form-item__checkbox:disabled + .form-item__label {
  color: rgba(93, 55, 36, 0.5);
  cursor: default; }
  .form-item__checkbox:disabled + .form-item__label::before {
    background-color: #aeb0b5;
    box-shadow: 0 0 0 1px #aeb0b5;
    cursor: not-allowed; }

.form-item--checkboxes {
  margin-bottom: 0; }

.form-item--radio {
  margin-bottom: 0.5em; }
  .form-item--radio .form-item__radio {
    appearance: none;
    border: 2px solid #CDBDAE;
    border-radius: 50%;
    cursor: pointer;
    height: 16px;
    margin-right: 3px;
    outline: none;
    position: relative;
    top: 2px;
    transition-duration: 0.2s;
    transition-property: border-color, border-width;
    transition-timing-function: ease-in-out;
    width: 16px; }
    .form-item--radio .form-item__radio:checked {
      border: 6px solid #AF4820; }
    .form-item--radio .form-item__radio:disabled {
      border: 2px solid rgba(205, 189, 174, 0.5);
      cursor: default; }
    .form-item--radio .form-item__radio + .form-item__label {
      cursor: pointer;
      display: inline; }
    .form-item--radio .form-item__radio:disabled + .form-item__label {
      color: rgba(93, 55, 36, 0.5);
      cursor: default; }

.form-item--radios {
  margin-bottom: 0; }

.form-item--range .form-item__range {
  appearance: none;
  background: transparent;
  border: 0;
  height: 2.75rem;
  margin: 0.2em 0;
  padding: 0; }
  .form-item--range .form-item__range:focus {
    box-shadow: none;
    outline: 0; }
    .form-item--range .form-item__range:focus::-moz-range-thumb {
      box-shadow: 0 0 3px #AF4820, 0 0 7px #AF4820; }
    .form-item--range .form-item__range:focus::-ms-thumb {
      box-shadow: 0 0 3px #AF4820, 0 0 7px #AF4820; }
    .form-item--range .form-item__range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 3px #AF4820, 0 0 7px #AF4820; }
  .form-item--range .form-item__range:disabled {
    cursor: default; }
    .form-item--range .form-item__range:disabled::-moz-range-thumb {
      background: rgba(255, 252, 247, 0.5);
      border-color: rgba(205, 189, 174, 0.5);
      cursor: default; }
    .form-item--range .form-item__range:disabled::-moz-range-track {
      background: rgba(255, 252, 247, 0.5);
      border-color: rgba(205, 189, 174, 0.5);
      cursor: default; }
    .form-item--range .form-item__range:disabled::-ms-thumb {
      background: rgba(255, 252, 247, 0.5);
      border-color: rgba(205, 189, 174, 0.5);
      cursor: default; }
    .form-item--range .form-item__range:disabled::-ms-fill-lower, .form-item--range .form-item__range:disabled::-ms-fill-upper {
      background: rgba(255, 252, 247, 0.5);
      border-color: rgba(205, 189, 174, 0.5);
      cursor: default; }
    .form-item--range .form-item__range:disabled::-webkit-slider-thumb {
      background: rgba(255, 252, 247, 0.5);
      border-color: rgba(205, 189, 174, 0.5);
      cursor: default; }
    .form-item--range .form-item__range:disabled::webkit-slider-runnable-track {
      background: rgba(255, 252, 247, 0.5);
      border-color: rgba(205, 189, 174, 0.5);
      cursor: default; }
  .form-item--range .form-item__range::-moz-range-thumb {
    background: #DE6335;
    border: 1px solid #F37029;
    border-radius: 0.1875rem;
    cursor: pointer;
    height: 2.75rem;
    outline: 0;
    width: 1.375rem; }
  .form-item--range .form-item__range::-moz-range-track {
    background: #FFFCF7;
    border: 1px solid #CDBDAE;
    cursor: pointer;
    display: block;
    height: 0.625rem;
    margin: 0;
    outline: 0;
    width: 100%; }
  .form-item--range .form-item__range::-ms-thumb {
    background: #DE6335;
    border: 1px solid #F37029;
    border-radius: 0.1875rem;
    cursor: pointer;
    height: 2.75rem;
    outline: 0;
    width: 1.375rem;
    margin-top: 0 !important; }
  .form-item--range .form-item__range::-ms-track {
    background: #FFFCF7;
    border: 1px solid #CDBDAE;
    cursor: pointer;
    display: block;
    height: 0.625rem;
    margin: 0;
    outline: 0;
    width: 100%;
    background: transparent;
    border: 0;
    color: transparent; }
  .form-item--range .form-item__range::-ms-fill-lower, .form-item--range .form-item__range::-ms-fill-upper {
    background: #FFFCF7;
    border: 1px solid #CDBDAE; }
  .form-item--range .form-item__range::-webkit-slider-thumb {
    background: #DE6335;
    border: 1px solid #F37029;
    border-radius: 0.1875rem;
    cursor: pointer;
    height: 2.75rem;
    outline: 0;
    width: 1.375rem;
    -webkit-appearance: none;
    margin-top: -1.125rem; }
  .form-item--range .form-item__range::-webkit-slider-runnable-track {
    background: #FFFCF7;
    border: 1px solid #CDBDAE;
    cursor: pointer;
    display: block;
    height: 0.625rem;
    margin: 0;
    outline: 0;
    width: 100%; }

.form-item--range.is-full-width > .form-item__range {
  width: 100%; }

.form-item--select .form-item__select {
  background-image: linear-gradient(transparent, transparent), url("../images/arrow-down.svg");
  background-position: right 0.8125rem center;
  background-repeat: no-repeat;
  background-size: 0.8125rem;
  padding-right: 2.0625rem; }
  [dir='rtl'] .form-item--select .form-item__select {
    background-position: left 0.8125rem center;
    padding-left: 2.0625rem;
    padding-right: 0; }
  .form-item--select .form-item__select::-ms-expand {
    display: none; }

.gallery__item-caption {
  max-width: 100%; }

.global-cta {
  background-color: #AF4820;
  color: #FFF5E8;
  padding: 2rem 0; }
  @media (min-width: 57.8125em) {
    .global-cta .l-grid--2col {
      align-items: center;
      grid-template-columns: 1.5fr 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 4rem; } }

.hero--program {
  margin-bottom: 0; }
  .hero--program .hero__content {
    padding-left: 4rem;
    padding-right: 4rem; }

.hero {
  color: #fff;
  padding: 0;
  position: relative;
  text-align: center; }
  @media (min-width: 125em) {
    .hero {
      max-width: 125rem;
      margin: 0 auto; } }
  .hero.has-overlay:after {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1; }
  .hero.has-overlay .hero__content {
    z-index: 2; }
  .hero .l-grid--2col {
    grid-template-columns: 160px 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 40px; }

.hero__title {
  margin-bottom: 0.25em; }

.hero__subtitle {
  margin-bottom: 0.25rem;
  font-family: "Noto Sans", serif; }

.hero__media {
  display: block;
  line-height: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 300px; }
  @media (min-width: 57.8125em) {
    .hero__media {
      min-height: 40vh;
      min-height: 510px; } }
  .hero__media img, .hero__media picture {
    width: 100%; }

.hero__content {
  background: rgba(0, 0, 0, 0.5);
  bottom: 5%;
  padding: 1em;
  position: absolute;
  right: 5%; }

.hero__caption-trigger {
  background-image: linear-gradient(transparent, transparent), url("../images/icon-caption.svg");
  height: 42px;
  overflow: hidden;
  width: 28px;
  position: absolute;
  right: 0;
  bottom: 20rem;
  z-index: 25;
  cursor: pointer; }
  .hero__caption-trigger::before {
    content: '';
    display: block;
    height: 150%;
    width: 0; }
  @media (min-width: 0) and (max-width: 57.8125em) {
    .hero__caption-trigger {
      top: 8rem;
      display: none; } }
  @media (min-width: 57.8125em) {
    .hero__caption-trigger {
      bottom: 20rem; } }

.hero__caption-text {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 10px solid #F37029;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  color: #fff;
  z-index: 14;
  opacity: 0;
  transition: all 0.3s linear; }
  .hero__caption-text p {
    max-width: 45%; }
  @media (min-width: 0) and (max-width: 57.8125em) {
    .hero__caption-text {
      opacity: 1;
      border: 0;
      top: 0;
      left: 0;
      width: 70%;
      bottom: auto;
      height: auto;
      background-color: rgba(0, 0, 0, 0.25);
      padding: 0.5rem;
      font-size: 12px; }
      .hero__caption-text p {
        margin: 0;
        width: 100%;
        max-width: 100%;
        text-align: left;
        line-height: 1.25; } }
  .hero__caption-text.is-open {
    animation-name: caption;
    animation-duration: 0.6s;
    opacity: 1; }

.hero__caption-mobile {
  display: block; }

@keyframes caption {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.hero__summary {
  margin-top: 0;
  margin-bottom: 0; }

.hero__thumbnail img {
  border-radius: 50%; }

body.artistsplaza .hero__title,
body.plaza-submission-form .hero__title {
  display: none; }

body.artistsplaza .hero-bg-image.has-overlay::after,
body.plaza-submission-form .hero-bg-image.has-overlay::after {
  background: none; }

.hero-bg-image {
  background-position: 50% 50%;
  background-size: cover;
  color: #fff;
  padding: 4em 1em;
  text-align: center;
  position: relative;
  background-image: url(../images/default-hero.jpg);
  min-height: 300px; }
  @media (min-width: 125em) {
    .hero-bg-image {
      margin: 0 auto;
      min-height: 40vh;
      max-width: 2000px; } }
  .hero-bg-image .hero-bg-image__subtitle {
    margin: 0;
    font-family: "Noto Sans", serif; }
  .hero-bg-image.hero--blog-detail .hero-bg-image__subtitle,
  .hero-bg-image.hero--blog-detail .hero-bg-image__title {
    text-align: left; }
    .hero-bg-image.hero--blog-detail .hero-bg-image__subtitle .hero__title,
    .hero-bg-image.hero--blog-detail .hero-bg-image__title .hero__title {
      text-align: left; }
  @media (min-width: 57.8125em) {
    .hero-bg-image {
      height: 510px; } }
  .hero-bg-image.has-overlay {
    position: relative; }
    .hero-bg-image.has-overlay:after {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1; }
    .hero-bg-image.has-overlay .hero-bg-image__content {
      z-index: 2; }
  .hero-bg-image .hero-bg-image__title {
    margin-top: 0;
    text-align: center;
    margin-bottom: 0;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }
    .hero--blog-detail .hero-bg-image .hero-bg-image__title {
      text-align: left; }
  .hero-bg-image .hero-bg-image__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    width: 100%;
    background-color: transparent;
    color: #fff;
    text-align: left;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }

.hero-bg-image__title {
  margin-bottom: 0.25em; }

.hero-bg-image__subtitle {
  text-align: center;
  font-family: "Noto Sans", serif; }

.hero-bg-image__type {
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }

.hero--detail-alt {
  margin-bottom: 4rem; }
  .hero--detail-alt .hero__title {
    margin-top: 0; }
  .hero--detail-alt .hero__content {
    position: static;
    width: 100%;
    background-color: #94a533;
    color: #FFF5E8;
    text-align: left;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .hero--detail-alt .hero__media img {
    max-height: 450px; }
  .hero--detail-alt.hero--program .hero__content {
    position: relative;
    top: -4rem;
    bottom: auto;
    left: auto;
    right: auto; }

.hero--detail {
  margin-bottom: 4rem; }
  .hero--detail .hero__title {
    margin-top: 0; }
  .hero--detail .hero__content {
    position: static;
    width: 100%;
    background-color: #AF4820;
    color: #fff;
    text-align: left;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .hero--detail .hero__media img {
    max-height: 450px; }
  .hero--detail.hero--program .hero__content {
    position: relative;
    top: -4rem;
    bottom: auto;
    left: auto;
    right: auto; }

.hero__homepage .hero-homepage {
  padding: 0;
  position: relative;
  background-color: #FFF5E8;
  height: 40vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top; }
  @media (min-width: 57.8125em) {
    .hero__homepage .hero-homepage {
      height: 80vh;
      min-height: 600px; } }
  @media (min-width: 91.25em) {
    .hero__homepage .hero-homepage {
      max-width: 125rem;
      margin-left: auto;
      margin-right: auto; } }
  @media (min-width: 57.8125em) {
    .hero__homepage .hero-homepage:after {
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: -1px bottom;
      height: 35.625rem;
      background-image: linear-gradient(transparent, transparent), url("../images/swoosh-smooth.svg");
      bottom: -15px;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: auto;
      z-index: 1; } }

.hero__homepage .hero-bg-image__content {
  background-color: #FFF5E8;
  z-index: 15;
  position: relative;
  margin-top: -1px;
  padding-bottom: 2rem; }
  @media (min-width: 0) and (max-width: 57.8125em) {
    .hero__homepage .hero-bg-image__content {
      padding-top: 3rem;
      padding-bottom: 3rem; } }

.hero__homepage .hero__title {
  color: #5D3724;
  text-align: left;
  text-shadow: none;
  background-image: linear-gradient(transparent, transparent), url("../images/circle.svg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 80px 80px;
  padding-left: 40px;
  padding-top: 30px;
  margin-bottom: 0; }
  @media (min-width: 68.75em) {
    .hero__homepage .hero__title {
      font-size: 4.5rem;
      background-size: 150px 150px;
      padding-left: 80px;
      padding-top: 60px; } }

.hero--large {
  margin-bottom: 4rem; }
  .hero--large .hero__title {
    margin-top: 0;
    text-align: center;
    margin-bottom: 0;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); }
  .hero--large .hero__subtitle {
    text-align: center; }
  .hero--large .hero__media {
    min-height: 510px; }
  .hero--large .hero__content {
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    width: 100%;
    background-color: transparent;
    color: #fff;
    text-align: left;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }

.image-teaser__image {
  background-size: cover;
  background-repeat: no-repeat;
  height: 24.0625rem;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 1rem;
  border-radius: 4px; }
  .image-teaser__image > * {
    width: 100%; }
  .image-teaser__image:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(55, 39, 23, 0);
    z-index: 4; }
  .image-teaser__image:hover .image-teaser__summary {
    display: block;
    opacity: 1; }
  .image-teaser__image:hover:before {
    opacity: 1;
    background-color: rgba(55, 39, 23, 0.8); }

.image-teaser__summary {
  display: none;
  opacity: 0;
  transition: all linear 0.2s;
  color: #fff;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  font-size: 24px;
  width: 100%;
  height: 100%;
  padding: 2rem;
  position: relative;
  z-index: 5;
  transition: all linear 0.2s; }
  .image-teaser__summary > * {
    width: 100%; }
  .image-teaser__summary p {
    margin-bottom: 1rem; }
  .image-teaser__summary .button {
    margin-bottom: 0; }

.list--border {
  list-style: none;
  margin-left: 0;
  padding-left: 0; }
  .list--border > li {
    border-bottom: 1px solid #ccc;
    padding: 0.25rem; }

.list--clean {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .list--clean li::before {
    display: none; }

.list--column {
  list-style: none;
  margin-left: 0;
  padding-left: 0; }
  @media (min-width: 31.25em) {
    .list--column {
      column-count: 2;
      column-gap: 2em; } }
  .list--column > li {
    break-inside: avoid-column;
    display: table;
    margin-bottom: 0;
    padding-bottom: 0.25em; }

.list--inline {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0; }
  .list--inline > li {
    display: inline-block; }

.list--pipeline {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0; }
  .list--pipeline::after {
    clear: both;
    content: '';
    display: table; }
  .list--pipeline > li {
    border-right: 1px solid #d6d7d9;
    display: block;
    float: left;
    margin-right: 0.75em;
    padding-right: 0.75em; }
    [dir='rtl'] .list--pipeline > li {
      border-left: 1px solid #d6d7d9;
      border-right: 0;
      float: right;
      margin-left: 0.75em;
      margin-right: 0;
      padding-left: 0.75em;
      padding-right: 0; }
    .list--pipeline > li.is-active {
      font-weight: bold; }
    .list--pipeline > li:last-child {
      border-right: 0;
      margin-right: 0;
      padding-right: 0; }
      [dir='rtl'] .list--pipeline > li:last-child {
        border-left: 0;
        margin-left: 0;
        padding-left: 0; }

.logo-grid {
  text-align: center;
  margin: 4rem 0; }
  .logo-grid .l-grid--4col,
  .logo-grid .l-grid--3col,
  .logo-grid .l-grid--1col,
  .logo-grid .l-grid--2col {
    grid-gap: 1rem;
    align-items: center;
    justify-content: center; }
  .logo-grid img {
    max-height: 200px; }

.menu {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .menu li::before {
    display: none; }
  @media print {
    .menu {
      display: none; } }

.menu__item {
  margin-bottom: 0; }

.menu--account {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0; }
  .menu--account::after {
    clear: both;
    content: '';
    display: table; }
  .menu--account > li {
    border-right: 1px solid #d6d7d9;
    display: block;
    float: left;
    margin-right: 0.75em;
    padding-right: 0.75em; }
    [dir='rtl'] .menu--account > li {
      border-left: 1px solid #d6d7d9;
      border-right: 0;
      float: right;
      margin-left: 0.75em;
      margin-right: 0;
      padding-left: 0.75em;
      padding-right: 0; }
    .menu--account > li.is-active {
      font-weight: bold; }
    .menu--account > li:last-child {
      border-right: 0;
      margin-right: 0;
      padding-right: 0; }
      [dir='rtl'] .menu--account > li:last-child {
        border-left: 0;
        margin-left: 0;
        padding-left: 0; }
  .menu--account .menu__link {
    font-size: 1em; }

.menu--footer {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0; }
  .menu--footer > li {
    display: inline-block; }
  .menu--footer .menu__item {
    margin: 0 0 1rem 0; }
  .menu--footer .menu__link {
    color: #7D4A30;
    text-decoration: none; }
    .menu--footer .menu__link:hover, .menu--footer .menu__link:focus {
      color: #AF4820; }
    .menu--footer .menu__link:active {
      color: #5D3724; }
  .menu--footer .menu__subnav {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .menu--footer .menu__subnav li::before {
      display: none; }
  .menu--footer .menu__heading {
    margin-bottom: 1rem; }
    .menu--footer .menu__heading > .menu__link {
      display: block;
      margin-bottom: 1rem; }
  .menu--footer .menu__donate .menu__link {
    background-color: #FBB731;
    border: 0;
    border-radius: 3px;
    color: #5D3724;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    line-height: 1.25;
    padding: 0.75rem 1.25rem;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    white-space: normal;
    margin-bottom: 0;
    height: auto;
    text-transform: uppercase; }
    .menu--footer .menu__donate .menu__link:focus, .menu--footer .menu__donate .menu__link:hover {
      background-color: #F79433;
      color: #5D3724;
      outline: 0; }
    .menu--footer .menu__donate .menu__link:active {
      background-color: #F79433;
      color: #5D3724; }
    .menu--footer .menu__donate .menu__link[disabled] {
      background-color: rgba(253, 185, 51, 0.5);
      color: #5D3724;
      cursor: default;
      pointer-events: none; }

.menu--languages {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0; }
  .menu--languages > li {
    display: inline-block; }

.menu--main {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
  position: relative;
  z-index: 51; }
  .menu--main > li {
    display: inline-block; }
  .menu--main .menu__item {
    display: inline-block;
    text-transform: uppercase;
    padding: 0;
    margin: 0 0.1875rem;
    vertical-align: bottom; }
    @media (min-width: 43.75em) and (max-width: 75em) {
      .menu--main .menu__item {
        margin: 0 0.75rem;
        max-width: 110px; } }
    @media (min-width: 75em) {
      .menu--main .menu__item {
        margin: 0 1rem;
        max-width: 140px; } }
    .menu--main .menu__item:hover .menu__subnav .menu__link, .menu--main .menu__item:focus .menu__subnav .menu__link {
      opacity: 1; }
    .menu--main .menu__item.menu__donate .menu__link {
      background-color: #FBB731;
      border: 0;
      border-radius: 3px;
      color: #5D3724;
      cursor: pointer;
      display: inline-block;
      font-size: 1rem;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-weight: 700;
      line-height: 1.25;
      padding: 0.75rem 1.25rem;
      text-align: center;
      text-decoration: none;
      vertical-align: top;
      white-space: normal;
      margin-bottom: 0;
      height: auto; }
      .menu--main .menu__item.menu__donate .menu__link:focus, .menu--main .menu__item.menu__donate .menu__link:hover {
        background-color: #F79433;
        color: #5D3724;
        outline: 0; }
      .menu--main .menu__item.menu__donate .menu__link:active {
        background-color: #F79433;
        color: #5D3724; }
      .menu--main .menu__item.menu__donate .menu__link[disabled] {
        background-color: rgba(253, 185, 51, 0.5);
        color: #5D3724;
        cursor: default;
        pointer-events: none; }
      @media (min-width: 0) and (max-width: 74.9375em) {
        .menu--main .menu__item.menu__donate .menu__link {
          font-size: 0.75rem;
          line-height: 1.4166666667;
          padding: 0.5rem 1rem; } }
      .menu--main .menu__item.menu__donate .menu__link::before {
        display: none; }
    .menu--main .menu__item.menu__search {
      margin: 0; }
      .menu--main .menu__item.menu__search .menu__link {
        background-repeat: no-repeat;
        background-size: 24px 24px;
        background-position: left 20px;
        display: block;
        font-size: 0.6875rem;
        font-weight: 400;
        height: 48px;
        overflow: hidden;
        width: 48px;
        background-image: linear-gradient(transparent, transparent), url("../images/icon--search.svg");
        text-indent: -99999em;
        transition: all ease-in-out 0.3s;
        text-align: left;
        white-space: nowrap;
        overflow: hidden; }
        .menu--main .menu__item.menu__search .menu__link::before {
          content: '';
          display: block;
          height: 150%;
          width: 0; }
        .menu--main .menu__item.menu__search .menu__link::before {
          display: none; }
        .menu--main .menu__item.menu__search .menu__link:hover {
          color: #F37029;
          text-indent: 0; }
    .menu--main .menu__item.current-menu-item .menu__link::before {
      background-color: #F37029;
      bottom: 3.9375rem;
      opacity: 1; }
  .menu--main .menu__link {
    color: #5D3724;
    font-size: 0.75rem;
    font-weight: 700;
    position: relative;
    text-decoration: none;
    height: 5rem; }
    @media (min-width: 0) and (max-width: 43.75em) {
      .menu--main .menu__link {
        font-size: 0.625rem; } }
    @media (min-width: 43.75em) and (max-width: 75em) {
      .menu--main .menu__link {
        font-size: 0.6875rem; } }
    @media (min-width: 75em) {
      .menu--main .menu__link {
        font-size: 0.875em; } }
    .menu--main .menu__link::before {
      content: '';
      width: 100%;
      background-color: transparent;
      height: 0.8125rem;
      left: 0;
      position: absolute;
      right: 0;
      bottom: 4.75rem;
      opacity: 0;
      transition: all ease-in-out 0.25s; }
    .menu--main .menu__link:hover::before {
      background-color: #F37029;
      bottom: 3.9375rem;
      opacity: 1; }
  .menu--main .menu__subnav {
    position: absolute;
    top: 75px;
    z-index: 10;
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: left;
    max-width: 98px;
    opacity: 0;
    transition: all ease-in-out 0.3s; }
    .menu--main .menu__subnav li::before {
      display: none; }
    .menu--main .menu__subnav .menu__item {
      text-transform: none;
      display: block;
      margin: 0 0 0.5rem 0;
      max-width: 100%;
      width: auto;
      position: relative;
      vertical-align: top;
      line-height: 1; }
    .menu--main .menu__subnav .menu__link {
      font-weight: 400;
      font-size: 0.6875rem;
      opacity: 0.5;
      padding-right: 0.25rem; }
      .menu--main .menu__subnav .menu__link::before {
        display: none; }
      .menu--main .menu__subnav .menu__link:hover {
        color: #AF4820; }

.menu__children {
  position: absolute;
  width: 100%;
  background-color: #FFF5E8;
  height: 0;
  left: 0;
  top: 0;
  transition: all linear 0.3s;
  z-index: 2; }
  .menu__children .l-constrain {
    position: relative; }
  .menu__children .newsletter-signup .l-grid--2col {
    grid-template-columns: 2fr 106px;
    grid-gap: 0; }
  .menu__children .newsletter-signup {
    width: 300px; }
  .menu__children .header__social {
    position: absolute;
    top: 300px;
    right: 0;
    opacity: 0;
    display: none;
    transition: all linear 0.13s; }
    .menu__children .header__social h4 {
      margin-bottom: 1rem; }
  .menu__children .header__languages {
    position: absolute;
    top: 200px;
    right: 0;
    opacity: 0;
    display: none;
    transition: all linear 0.13s; }
    .menu__children .header__languages h4 {
      margin-bottom: 1rem; }

.header__second:not(.header-search-open):hover {
  overflow: visible; }
  .header__second:not(.header-search-open):hover .menu--main + .menu__children {
    height: 31.25rem; }
  .header__second:not(.header-search-open):hover .menu__subnav {
    opacity: 1;
    display: block; }
  .header__second:not(.header-search-open):hover .newsletter-signup,
  .header__second:not(.header-search-open):hover .header__social,
  .header__second:not(.header-search-open):hover .header__languages {
    opacity: 1;
    display: block; }

.menu--mobile {
  margin: 0.75em 0 0 0; }
  .menu--mobile .menu__item {
    margin: 0;
    padding: 0; }
    .menu--mobile .menu__item.menu__donate, .menu--mobile .menu__item.menu__search {
      display: none; }
  .menu--mobile .menu__link {
    background-color: transparent;
    border: 0;
    color: #555559;
    display: block;
    font-size: 1.125em;
    font-weight: bold;
    line-height: 1.25;
    margin: 0;
    padding: 0.5972222222em 1.25em;
    position: relative;
    text-align: left;
    text-decoration: none;
    white-space: normal;
    width: auto;
    z-index: 3; }
    .menu--mobile .menu__link:focus, .menu--mobile .menu__link:hover, .menu--mobile .menu__link:active, .menu--mobile .menu__link.is-active {
      color: #000; }
  .menu--mobile .menu__item--parent {
    position: relative; }
    .menu--mobile .menu__item--parent.is-open {
      background: #fff;
      background: rgba(255, 255, 255, 0.98); }
    .menu--mobile .menu__item--parent > .menu__link {
      display: inline-block; }
  .menu--mobile .menu__subnav-arrow {
    background: none;
    height: 44px;
    overflow: hidden;
    width: 100%;
    background-image: linear-gradient(transparent, transparent), inline("../images/mobile-arrow-down.svg");
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-size: 19px 12px;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    height: 2.75em;
    position: absolute;
    right: 0;
    top: 0;
    vertical-align: top;
    z-index: 2; }
    .menu--mobile .menu__subnav-arrow::before {
      content: '';
      display: block;
      height: 150%;
      width: 0; }
    .menu--mobile .menu__subnav-arrow:focus {
      outline: thin dotted; }
    .menu--mobile .menu__subnav-arrow.is-active {
      background-image: linear-gradient(transparent, transparent), inline("../images/mobile-arrow-up.svg"); }
    [dir='rtl'] .menu--mobile .menu__subnav-arrow {
      left: 0;
      right: auto; }
  .menu--mobile .menu__subnav {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0; }
    .menu--mobile .menu__subnav .menu__link {
      color: #555559;
      padding-left: 2em; }
      .menu--mobile .menu__subnav .menu__link:focus, .menu--mobile .menu__subnav .menu__link:hover, .menu--mobile .menu__subnav .menu__link:active {
        color: #000; }

.menu--partners {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0; }
  .menu--partners > li {
    display: inline-block; }

.menu--social {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0; }
  .menu--social > li {
    display: inline-block; }
  .menu--social .menu__item {
    margin-right: 0.5rem; }
  .menu--social .menu__link {
    display: block;
    height: 24px;
    overflow: hidden;
    width: 24px; }
    .menu--social .menu__link::before {
      content: '';
      display: block;
      height: 150%;
      width: 0; }
  .menu--social .menu__facebook .menu__link {
    background-image: linear-gradient(transparent, transparent), url("../images/icon-facebook.svg"); }
  .menu--social .menu__twitter .menu__link {
    background-image: linear-gradient(transparent, transparent), url("../images/icon-twitter.svg"); }
  .menu--social .menu__instagram .menu__link {
    background-image: linear-gradient(transparent, transparent), url("../images/icon-instagram.svg"); }
  .menu--social .menu__youtube .menu__link {
    background-image: linear-gradient(transparent, transparent), url("../images/icon-youtube.svg"); }
  .menu--social .menu__email .menu__link {
    background-image: linear-gradient(transparent, transparent), url("../images/icon-email.svg"); }

.messages {
  background-color: #ecf6fa;
  background-position: 0.625em 0.625em;
  background-repeat: no-repeat;
  background-size: 2.5em;
  border: 1px solid #bcdeee;
  color: #424242;
  margin-bottom: 2.5rem;
  overflow: hidden;
  padding: 1em;
  position: relative;
  word-wrap: break-word; }
  .messages::before {
    background-repeat: no-repeat;
    background-size: 3.25em;
    bottom: -.5em;
    content: '';
    display: block;
    height: 3.25em;
    position: absolute;
    right: -.5em;
    transform: rotate(-20deg);
    width: 3.25em; }
  .messages a {
    color: #bcdeee;
    font-weight: 600;
    text-decoration: underline; }
  @media print {
    .messages {
      background-image: none !important; } }

.messages__content > *:last-child {
  margin-bottom: 0; }

.messages--status {
  background-color: #eff8e6;
  border-color: #c9e8a9; }
  .messages--status::before {
    background-image: linear-gradient(transparent, transparent), url("../images/messages-status.svg"); }
  .messages--status a {
    color: #71b02f; }

.messages--error {
  background-color: #ffe6e5;
  border-color: #ffc5c2; }
  .messages--error::before {
    background-image: linear-gradient(transparent, transparent), url("../images/messages-error.svg"); }
  .messages--error a {
    color: #ff645c; }
  .messages--error .messages__item em:first-child {
    font-style: normal;
    font-weight: bold; }

.messages--warning {
  background-color: #fcf8e3;
  border-color: #faf2cc; }
  .messages--warning::before {
    background-image: linear-gradient(transparent, transparent), url("../images/messages-warning.svg"); }
  .messages--warning a {
    color: #8a6d3b; }

.meta {
  text-transform: uppercase;
  font-weight: 700; }
  .meta span {
    display: inline-block; }
    .meta span:before {
      content: '\2219';
      display: inline-block;
      margin-left: 4px;
      margin-right: 4px; }
    .meta span:first-child:before, .meta span:only-child:before {
      display: none; }
  .hero--detail .meta {
    font-size: 12px; }

@media (min-width: 57.8125em) {
  .mobile-menu {
    display: none; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .mobile-menu {
    display: block; } }

.mobile-menu__bar {
  background-color: #FFF5E8;
  border-bottom: 1px solid #CDBDAE;
  box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.4);
  color: #5D3724;
  height: 4.25rem;
  text-align: center; }

.mobile-menu__donate {
  background-color: #FBB731;
  border: 0;
  border-radius: 3px;
  color: #5D3724;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75rem 1.25rem;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: normal;
  font-size: 1rem;
  line-height: 1.0625;
  padding: 0.5rem 1rem;
  float: right;
  margin-bottom: 0;
  margin-top: 1rem; }
  .mobile-menu__donate:focus, .mobile-menu__donate:hover {
    background-color: #F79433;
    color: #5D3724;
    outline: 0; }
  .mobile-menu__donate:active {
    background-color: #F79433;
    color: #5D3724; }
  .mobile-menu__donate[disabled] {
    background-color: rgba(253, 185, 51, 0.5);
    color: #5D3724;
    cursor: default;
    pointer-events: none; }

.mobile-menu__button {
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  border: 0;
  color: #5D3724;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: bold;
  height: 4.25rem;
  line-height: 4.25rem;
  margin: 0;
  padding: 0 1rem;
  text-decoration: none; }
  .mobile-menu__button:focus {
    outline: thin dotted; }

.mobile-menu__button--menu {
  float: right; }

.mobile-menu__button--search {
  float: right; }

.mobile-menu__icon {
  height: 22px;
  overflow: hidden;
  width: 22px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 22px 22px;
  display: inline-block;
  margin-top: 1rem; }
  .mobile-menu__icon::before {
    content: '';
    display: block;
    height: 150%;
    width: 0; }

.is-active .mobile-menu__icon--menu, .is-active .mobile-menu__icon--search {
  background-image: linear-gradient(transparent, transparent), inline("../images/mobile-close.svg"); }

.mobile-menu__icon--menu {
  background-image: linear-gradient(transparent, transparent), inline("../images/mobile-menu.svg");
  background-size: 33px 22px;
  width: 33px; }
  .is-active .mobile-menu__icon--menu {
    background-size: 22px 22px; }

.mobile-menu__icon--search {
  background-image: linear-gradient(transparent, transparent), inline("../images/mobile-search.svg"); }

.mobile-menu__links {
  background-color: #FFF5E8;
  background-color: #FFF5E8;
  color: #5D3724;
  position: absolute;
  width: 100%;
  z-index: 100; }

.mobile-menu__search {
  display: none; }
  .mobile-menu__search form,
  .mobile-menu__search .form-item,
  .mobile-menu__search .form-text {
    width: 100%; }
  .mobile-menu__search .form-text {
    box-sizing: border-box; }

.moons,
.moons * {
  transition: all 0.75s linear; }

.moons {
  position: relative;
  height: 18px;
  overflow: hidden;
  animation-name: moons;
  animation-duration: 0.6s;
  width: 73px;
  max-width: 73px; }
  .hero-homepage .moons {
    z-index: 4; }
  .moons::before {
    content: '';
    height: 1px;
    background-color: #F37029;
    position: absolute;
    left: 0;
    width: 100%;
    top: 7px;
    z-index: 1; }

.moon {
  display: inline-block;
  margin-right: 1rem;
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: #F37029;
  border-radius: 50%;
  border: 2px solid #F37029;
  box-shadow: 0 0 0 3px white;
  z-index: 1;
  left: 0; }
  .moon::before {
    content: '';
    position: absolute;
    background-color: white;
    width: 10px;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    left: 0;
    z-index: 0; }

.moon--full::before {
  display: none; }

.moon--half {
  left: 28px; }
  .moon--half::before {
    height: 5px; }

.moon--empty {
  left: 56px; }
  .moon--empty::before {
    height: 10px;
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px; }

@keyframes moons {
  0% {
    width: 0;
    opacity: 0; }
  12% {
    opacity: 1; }
  100% {
    width: 73px; } }

.moons--horizontal {
  height: 82px;
  width: 14px;
  max-width: 14px;
  animation-name: moons_height; }
  .moons--horizontal.moons::before {
    content: '';
    height: 73px;
    background-color: #F37029;
    position: absolute;
    left: 0;
    width: 1px;
    left: 7px;
    z-index: 0; }
  .moons--horizontal .moon {
    display: inline-block;
    margin-right: 0;
    position: relative;
    z-index: 1;
    margin-bottom: 1rem;
    box-shadow: none;
    left: auto;
    right: auto;
    bottom: auto;
    top: auto; }
    .moons--horizontal .moon::before {
      left: 0; }
  .moons--horizontal .moon--half::before {
    width: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;
    left: 5px;
    bottom: 0;
    height: 10px; }
  .moons--horizontal .moon--empty::before {
    background-color: #372717; }

@keyframes moons_height {
  0% {
    height: 0;
    opacity: 0; }
  12% {
    opacity: 1; }
  100% {
    height: 82px; } }

.moons--overlay {
  position: absolute;
  left: 50%;
  margin-left: -14px;
  top: -60.5px; }

@media (min-width: 0) and (max-width: 50em) {
  .newsletter-signup {
    display: none; } }

.newsletter-signup h6 {
  margin-bottom: 1rem; }

.footer .newsletter-signup {
  margin-top: 4rem; }

.newsletter-signup .form-item__text {
  border-radius: 0.1875rem;
  margin: 0; }

.newsletter-signup .button {
  margin-bottom: 0;
  height: 2.75rem; }
  @media (min-width: 0) and (max-width: 61.5625em) {
    .newsletter-signup .button {
      font-size: 16px; } }

.newsletter-signup .l-grid--2col {
  grid-gap: 0;
  margin-bottom: 0; }
  @media (min-width: 0) and (max-width: 64em) {
    .newsletter-signup .l-grid--2col {
      display: block; } }
  @media (min-width: 0) and (max-width: 64em) {
    .newsletter-signup .l-grid--2col * {
      width: 100%; } }

.menu__children .newsletter-signup {
  position: absolute;
  right: 0;
  top: 400px;
  opacity: 0;
  display: none; }

#mc_embed_shell {
  max-width: 600px;
  margin: 20px auto; }

#mc_embed_signup {
  background: #fff;
  clear: left;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); }

#mc_embed_signup .mc-field-group.input-group ul li {
  display: inline-flex !important; }

#mc_embed_signup .mc-field-group.input-group label {
  padding-left: 10px; }

#mc_embed_signup .button {
  height: unset !important;
  line-height: unset !important;
  background-color: #FBB731 !important;
  border: 0 !important;
  border-radius: 3px !important;
  color: #5D3724 !important;
  cursor: pointer !important;
  display: inline-block !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  line-height: 1.25 !important;
  padding: 0.75rem 1.25rem !important;
  text-align: center !important;
  text-decoration: none !important;
  vertical-align: top !important;
  white-space: normal !important;
  margin-bottom: 0 !important; }

.note {
  display: flex;
  margin: 3rem 0; }
  @media (min-width: 75em) {
    .note {
      float: right;
      margin-right: -200px;
      width: 250px; } }
  .l-sidebar--side-menu .note {
    display: flex;
    margin: 3rem 0; }
    @media (min-width: 100em) {
      .l-sidebar--side-menu .note {
        float: right;
        margin-right: -250px;
        width: 250px; } }

.note__label {
  cursor: pointer;
  padding: 0 2em 0 1.5em;
  position: relative;
  width: 115px; }
  .note__label:before {
    background-image: linear-gradient(transparent, transparent), url("../images/icon-related-note.svg");
    content: '';
    background-size: 18px 12px;
    background-position: center;
    background-repeat: no-repeat;
    height: 12px;
    left: 0;
    position: absolute;
    top: 8px;
    width: 18px; }
  .note__label:after {
    content: '';
    background-color: red;
    display: block;
    height: 128px;
    left: 95px;
    position: absolute;
    top: -45px;
    transform: rotate(70deg);
    transition: all .4s ease-in-out;
    width: 1px; }
  .note__label p {
    color: #372717;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 1.1;
    margin-bottom: 0; }
  .is-open .note__label:after {
    height: 100%;
    left: 75px;
    transform: rotate(0deg);
    top: 0; }

.note__content {
  opacity: 0;
  transform: translateX(5%);
  transition: visibility 0s linear 0.5s, opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  visibility: hidden;
  padding-left: 0.6rem; }
  .note__content p {
    color: #372717;
    font-size: 12px;
    line-height: 1.6; }
  .note__content a {
    text-transform: uppercase;
    font-size: 12px; }
  .is-open .note__content {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    transition-delay: 0s; }

.outline-box-orange {
  background-color: #FFF5E8;
  border: 6px solid #AF4820;
  padding: 2rem; }
  .l-grid .outline-box-orange {
    margin-top: 0; }
  .outline-box-orange .l-grid--2col {
    align-items: center; }
  .outline-box-orange h3 {
    margin-bottom: 1rem; }

@media (min-width: 57.8125em) {
  .outline-box-orange__alignment--right {
    order: 2; } }

.outline-box-orange__last img {
  border-radius: 50%; }

.outline-box {
  border: 4px solid #94a533;
  padding: 2rem;
  margin: 4rem 0; }
  .l-grid .outline-box {
    margin-top: 0; }
  .outline-box .l-grid--2col {
    align-items: center; }

@media (min-width: 57.8125em) {
  .outline-box__alignment--right {
    order: 2; } }

.pager__items {
  list-style-type: none;
  margin: 0;
  padding: 0;
  clear: both;
  text-align: center; }
  .pager__items li::before {
    display: none; }

.pager__item {
  display: inline-block;
  line-height: 1;
  margin: 0;
  padding: 0;
  text-align: center; }

.program-slides {
  padding-bottom: 4rem; }
  @media (min-width: 37.5em) {
    .program-slides {
      margin-bottom: 8rem; }
      .program-slides + * {
        position: relative;
        margin-top: -11rem; } }
  .program-slides .l-grid--2col {
    grid-gap: 6rem;
    display: flex;
    flex-wrap: wrap;
    margin-left: -1em;
    margin-right: -1em; }
    .program-slides .l-grid--2col > * {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: calc((100%) - 2em);
      margin-left: 1em;
      margin-right: 1em;
      margin-bottom: 2em;
      min-width: 0; }
    @supports (display: grid) {
      .program-slides .l-grid--2col {
        margin-left: 0;
        margin-right: 0;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 2em;
        display: grid; }
        .program-slides .l-grid--2col > * {
          margin: 0;
          min-width: 0;
          width: 100%; } }
    @media (min-width: 50em) {
      .program-slides .l-grid--2col {
        align-items: baseline; }
        .program-slides .l-grid--2col > * {
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: auto;
          width: calc((50%) - 2em);
          margin-left: 1em;
          margin-right: 1em;
          margin-bottom: 2em;
          min-width: 275px; }
        @supports (display: grid) {
          .program-slides .l-grid--2col {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 2em; }
            .program-slides .l-grid--2col > * {
              margin: 0;
              min-width: 0;
              width: 100%; } } }

.program-slide {
  position: relative; }

.program-slide__media {
  position: relative;
  opacity: 0;
  width: 100%; }
  .program-slide__media.is-active {
    opacity: 1; }

.program-slide__image {
  display: none; }
  .is-active .program-slide__image {
    display: block; }
  @media (min-width: 57.8125em) {
    .program-slide__image {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      display: block; } }

.program-slide__list {
  margin-top: 2rem; }
  @media (min-width: 57.8125em) {
    .program-slide__list {
      background-image: linear-gradient(transparent, transparent), url("../images/choppy-circle.svg");
      background-position: left top;
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 11rem;
      padding-left: 8rem;
      padding-bottom: 4rem;
      min-height: 800px; } }
  .program-slide__list ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .program-slide__list ul li::before {
      display: none; }
  .program-slide__list li {
    margin-bottom: 0.75rem; }
    @media (min-width: 57.8125em) {
      .program-slide__list li {
        margin-bottom: 1.25rem; } }
  .program-slide__list a {
    display: inline-block; }
    .program-slide__list a:after {
      content: '';
      display: block;
      width: 2rem;
      height: 0.25rem;
      background-color: #F37029;
      margin-top: 1rem;
      transition: all 0.15s linear; }
    .program-slide__list a:hover:after {
      width: 100%; }

@media (min-width: 57.8125em) {
  .program-slide__caption {
    position: absolute;
    top: calc(100% + 2rem);
    right: 8rem;
    left: 2rem;
    font-style: italic;
    font-size: 14px;
    color: #372717; } }

.program-slide {
  max-height: 25rem; }

.progress {
  position: relative; }

.progress__track {
  background-color: #aeb0b5;
  border: 1px solid #5b616b;
  border-radius: 0.1875rem;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
  height: 1rem;
  margin: 0.2em 0;
  max-width: 100%;
  min-width: 6em;
  overflow: hidden; }

.progress__bar {
  background-color: #AF4820;
  border-radius: 0.1875rem;
  height: 1rem;
  transition-duration: 0.5s;
  transition-property: width;
  transition-timing-function: ease-out;
  width: 3%; }

.progress__description,
.progress__percentage {
  color: #AF4820;
  font-size: 0.75rem;
  overflow: hidden; }

.progress__description {
  float: left; }
  [dir='rtl'] .progress__description {
    float: right; }

.progress__percentage {
  float: right; }
  [dir='rtl'] .progress__percentage {
    float: left; }

.related {
  position: relative;
  background-color: #372717;
  color: #FFF5E8;
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-top: 8rem; }
  .related + .related {
    margin-top: 0; }
  @media (min-width: 37.5em) {
    .related .l-grid--2col {
      grid-template-columns: 175px 1fr;
      align-items: center; } }
  .related h3 {
    text-align: center;
    margin-bottom: 2rem; }
  .related h4 {
    font-weight: 400;
    margin-bottom: 0.5rem; }
  .related a {
    color: #FFF5E8; }
    .related a:hover, .related a:focus {
      color: #FBB731; }

.related__meta {
  color: #C8D25B;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  margin-top: 2rem; }

.related__link {
  margin-top: 4rem;
  text-align: center; }
  .related__link a {
    color: #FFF5E8;
    font-family: "Noto Serif", sans-serif;
    font-weight: 700;
    border-bottom: 4px solid #FBB731;
    padding-bottom: 2px;
    text-decoration: none; }

.related__image {
  text-align: center; }

.roster-item__detail-header {
  border-bottom: 2px solid #f1f1f1;
  font-size: 24px;
  padding: 0.625rem 0;
  margin-bottom: 0.625rem; }

h4 .roster-item__detail-header {
  color: #AF4820; }

.roster-item__meta {
  font-size: 12px;
  margin-bottom: 1.25rem; }

.single-artist_roster_item .addtoany_share_save_container {
  display: none; }

.roster-item__about {
  display: block; }
  @media (min-width: 50em) {
    .roster-item__about {
      display: flex; } }

.roster-item__about-text {
  margin-bottom: 1.25rem; }

.roster-item__about-photo {
  margin-bottom: 1.25rem; }

.searchform--header {
  display: flex;
  height: 0;
  overflow: hidden;
  transition: all ease-in-out 0.2s; }
  @media (min-width: 0) and (max-width: 57.8125em) {
    .searchform--header:not(.mobile-menu__search) {
      display: none; } }
  .searchform--header.mobile-menu__search > * {
    margin: 1rem 0.5rem;
    width: calc(60% - 1rem); }
  .searchform--header.mobile-menu__search > *:last-child {
    width: calc(40% - 1rem); }
  .searchform--header.mobile-menu__search.is-open {
    height: 4.75rem; }
  .searchform--header > * {
    margin: 1rem 1rem;
    width: calc(80% - 2rem); }
  .searchform--header > *:last-child {
    width: calc(20% - 2rem); }
  .is-open .searchform--header {
    height: 4.75rem; }

.site-logo-round {
  display: block;
  max-width: 11.25rem;
  margin-bottom: 2rem; }

.site-logo--mobile {
  background-repeat: no-repeat;
  display: inline-block;
  float: left;
  margin: 0.25rem 0 0 0.75rem;
  background-image: linear-gradient(transparent, transparent), url("../images/acta-logo-25th-stamp.svg");
  height: 60px;
  overflow: hidden;
  width: 60px; }
  .site-logo--mobile::before {
    content: '';
    display: block;
    height: 150%;
    width: 0; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 33%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-size: 40px;
    font-weight: bold;
    line-height: 1;
    color: #ff6900;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -45px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -40px; }
  .slick-prev:before {
    content: "〈"; }
    [dir="rtl"] .slick-prev:before {
      content: "〉"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "〉"; }
    [dir="rtl"] .slick-next:before {
      content: "〈"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slides {
  clear: both;
  margin: 4em 0;
  padding: 4rem 0;
  background-color: #fff;
  color: #212121; }
  .slides .slick-slide {
    display: block;
    width: 100%; }
  .slides .slick-slider {
    margin: 0 0 2em; }
  .slides .slick-track {
    border-radius: 4px;
    overflow: hidden; }
  .slides h2 {
    margin: 0 0 2rem;
    text-align: center; }

@media (min-width: 50em) {
  .slides--small {
    margin: 0 0 3em;
    max-width: calc(100% - 304px - 200px); } }

.slides__slideshow {
  margin-top: 2em; }

.slide__item img {
  border-radius: 4px;
  margin: auto; }

.slide__item p {
  font-size: 16px;
  font-style: italic;
  margin: 1em auto 2em;
  max-width: 600px;
  text-align: center; }
  @media (min-width: 50em) {
    .slide__item p {
      margin: 2em auto; } }

.slides__prev,
.slides__next {
  height: 25px;
  overflow: hidden;
  width: 16px;
  background-repeat: no-repeat;
  cursor: pointer;
  margin: 0 .5em; }
  .slides__prev::before,
  .slides__next::before {
    content: '';
    display: block;
    height: 150%;
    width: 0; }

.slides__prev {
  background-image: linear-gradient(transparent, transparent), url("../images/arrow-prev.svg"); }

.slides__next {
  background-image: linear-gradient(transparent, transparent), url("../images/arrow-next.svg"); }

.slides__wrapper {
  border-radius: 4px;
  max-width: 80%;
  overflow: hidden; }
  @media (min-width: 50em) {
    .slides__wrapper {
      max-width: 600px; } }

.spotlight {
  background-color: #AF4820;
  color: #FFF5E8;
  padding: 3.75rem 0 2.5rem 0; }
  @media (min-width: 43.75em) {
    .spotlight .l-grid--3col {
      align-items: center;
      grid-gap: 2.5rem;
      grid-template-columns: 200px 1fr 1fr; } }
  @media (min-width: 54.375em) {
    .spotlight .l-grid--3col {
      grid-template-columns: 200px 1fr 2fr; } }
  @media (min-width: 72.5em) {
    .spotlight .l-grid--3col {
      grid-template-columns: 200px 1fr 455px; } }
  .spotlight .button {
    margin-bottom: 0; }

.tabs::after {
  clear: both;
  content: '';
  display: table; }

.tabs__items {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column; }
  .tabs__items li::before {
    display: none; }
  @media (min-width: 37.5em) {
    .tabs__items {
      flex-direction: row; } }
  @media print {
    .tabs__items {
      display: none; } }

.tabs__item:first-child .tabs__link {
  border-bottom-left-radius: 0.1875rem;
  border-left: 0;
  border-top-left-radius: 0.1875rem; }

.tabs__item:last-child .tabs__link {
  border-bottom-right-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem; }

.tabs__link {
  background-color: #d6d7d9;
  border: 0;
  border-radius: 0;
  color: #212121;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75rem 1.25rem;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: normal;
  border-left: 1px solid #aeb0b5;
  display: block;
  font-size: 1rem;
  line-height: 1.0625;
  margin: 0;
  padding: 0.5rem 1rem; }
  .tabs__link:focus, .tabs__link:hover {
    background-color: #aeb0b5;
    color: #212121;
    outline: 0; }
  .tabs__link:active {
    background-color: #212121;
    color: #fff; }
  .tabs__link[disabled] {
    background-color: #d6d7d9;
    color: #323a45;
    cursor: default;
    pointer-events: none; }
  .tabs__link:active {
    background-color: #212121;
    color: #fff; }
  .tabs__link:focus {
    background-color: #aeb0b5; }
  .tabs__link:hover, .tabs__link:visited {
    color: #212121; }
  .tabs__link.is-active {
    background-color: #212121;
    color: #fff; }
    .tabs__link.is-active:hover {
      color: #fff; }

.tag {
  display: inline-block;
  background-color: #FFF5E8;
  color: #AF4820;
  font-size: 0.9375rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-radius: 4px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem; }

.tag--acta-event {
  display: block;
  font-size: 22px;
  font-family: "Noto Serif", sans-serif;
  background-color: #fff;
  color: #5D3724;
  background-image: linear-gradient(transparent, transparent), url("../images/acta-logo--sm.svg");
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: 0.5rem center;
  padding-left: 2.25rem; }

.teaser-spotlight {
  border-bottom: 2px solid #DE6335;
  margin-bottom: 2rem;
  padding-bottom: 2rem; }
  @media (min-width: 0) and (max-width: 57.8125em) {
    .teaser-spotlight {
      text-align: center; } }
  .teaser-spotlight:last-child {
    border-bottom: 0; }
  .teaser-spotlight a:not(.button) {
    color: #FFF5E8; }

.teaser-spotlight__image {
  height: 160px; }
  .teaser-spotlight__image img {
    border-radius: 50%;
    max-height: 175px; }

.teaser-spotlight__title {
  margin-bottom: 0;
  text-align: left; }

.teaser-spotlight__summary {
  margin-bottom: 1rem; }

.spotlight__description {
  text-align: left; }

.text--center {
  text-align: center; }

.text--large {
  font-size: 24px; }

.clearfix::after {
  clear: both !important;
  content: '' !important;
  display: table !important; }

.visually-hidden {
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  position: absolute !important;
  width: 1px !important;
  word-wrap: normal !important; }
  .visually-hidden.focusable:active, .visually-hidden.focusable:focus {
    clip: auto !important;
    height: auto !important;
    overflow: visible !important;
    position: static !important;
    width: auto !important; }

.hidden {
  display: none !important; }

.invisible {
  visibility: hidden !important; }

.skiplinks {
  left: 50%;
  margin-left: -7em;
  position: absolute;
  width: 14em;
  z-index: 9999; }

.skiplinks__link,
.skiplinks__link:visited,
.skiplinks__link:focus {
  background-color: #333;
  border: 2px solid #eee;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  color: #eee;
  display: inline-block;
  font-weight: bold;
  line-height: 1.5;
  margin: 0;
  outline: 0;
  padding: 0.25em 0.5em;
  text-align: center;
  text-decoration: none;
  width: 14em; }

#main:focus {
  outline: 0; }

/*# sourceMappingURL=styles.css.map */